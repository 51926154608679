.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
   
.section-container {
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 24px;
  text-align: center;
}    

.section-container-qr {
  background: #ffffff;
  text-align: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}    

.centered-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* optional */
}
.top-view {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust color and opacity */
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-view input {
  margin: 10px 0;
  padding: 20px;
  border: none;
  border-radius: 4px;
  width: 300px;
  text-align: center;
}

.top-view button {
  padding: 20px 60px;
  border: none;
  border-radius: 4px;
  background-color: #F04F9F;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.section-container-qr button {
  padding: 20px 60px;
  border: none;
  border-radius: 4px;
  background-color: #F04F9F;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.top-view button:hover {
  background-color: #4fa3b7;
}



@font-face {
  font-family: "DFVN";   /*Can be any text*/
  src: local("DFVNObelixPro"),
    url("./fonts/DFVNObelixPro.ttf") format("truetype");
}